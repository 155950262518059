import React, { useState } from "react";
import "../Styles/AddMoneyModal.css";
import InputField from "./AddMoneyInputFeild";
import DefaultValues from "./AddMoneyDefaultValue";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { PriceValidate } from "../Validation/Validation";

function AddMoneyModal({ isOpen, onAddMoney, onCancel }) {
  const [walletInputValue, setWalletInputValue] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const WalletBalance = useSelector((state) => state.wallet.balance);

  const handleAddMoney = (amount) => {
    
    if (PriceValidate(amount) || amount !== "") {
      setWalletInputValue(amount);
      setSelectedButton(amount)
      console.log(amount)
    }
  };
  return isOpen ? (
    <div className="AddMoneyModal_Div">
      <div className="AddMoneyModal_Content_Div">
        <div className="AddMoneyModal_TextArea">
          <h3 className="AddMoneyModal_Heading_H3">Add Money to Wallet</h3>
          <p>
            Current Wallet Balance:
            <span
              style={{ fontWeight: 600, color: "green", fontSize: "1.2rem" }}
            >
              ${WalletBalance ? parseFloat(WalletBalance).toFixed(2) : "0.00"} 
            </span>{" "}
          </p>  
          <InputField
            onAddMoney={handleAddMoney}
            inputValue={walletInputValue}
          />
          <DefaultValues
            onAddMoney={handleAddMoney}
            selectedButton={selectedButton}
          />
        </div>
        <div className="AddMoneyModal_Button_Div">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="AddMoneyModal_Button"
            onClick={() =>{ 
              setSelectedButton(null)
              setWalletInputValue(null)
              onAddMoney(walletInputValue || selectedButton)
            }}
          >
            AddMoney
          </motion.button>
          <button className="AddMoneyModal_Button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default AddMoneyModal;
