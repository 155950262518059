// StarRating.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../Styles/StarRating.css'; // Import your styles

const StarRating = ({ totalStars, initialRating, onRatingChange = ()=> 5,fontSize = '1.5rem'}) => {
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
    onRatingChange(selectedRating);
  };

  return (
    <div className="star-rating" style={{fontSize:fontSize}} >
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          className={index < rating ? 'star filled' : 'star'}
          onClick={() => handleStarClick(index + 1)}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default StarRating;

