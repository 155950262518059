import React from 'react'
import { useState ,useEffect,useReducer} from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import AddMoneyModal from './AddMoneyModal'
import axios from 'axios'
import { Baseurl } from 'API'
import { BsPlus } from 'react-icons/bs'
import { useSelector , useDispatch } from "react-redux";
import { fetchWalletBalance } from "../redux/Action/WalletAction";
import useTop from './ScrollTop'
import "../MyAccount/UserProfile.css"
import { deposit } from 'redux/Reducers/WalletSlice'

const FarmproduceWallet = () => {

    const [isAddMoneyModalOpen, setIsAddMoneyModalOpen] = useState(false);
    const WalletBalance = useSelector((state) => state.wallet.balance);
    console.log(WalletBalance);
    const User = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const history = useLocation();
    const [accountDetails, setAccountDetails] = useState({});
    const dataFetchReducer = (state, action) => {
        switch (action.type) {
          case 'FETCH_INIT':
            return { ...state, loading: true, error: null };
          case 'FETCH_SUCCESS':
            return { ...state, loading: false, data: action.payload };
          case 'FETCH_FAILURE':
            return { ...state, loading: false, error: action.payload };
          default:
            return state;
        }
      };
    const [state, dispatchAction] = useReducer(dataFetchReducer, {
        loading: true,
        data: null,
        error: null,
      });
    const HandleAddMoneyClick = async (amount) => {
        try {
          // console.log("MoneyAddedSuccessfully", amount, User);
          // const response = await axios.post(`${Baseurl}Wallet/AddWallet`, {
          //   user_id: User[0].id,
          //   wallet_id: User[0].wallet_id,
          //   transaction_type:'deposit',
          //   amount:parseFloat(amount),
          // });
          console.log(User[0].id,User[0].wallet_id,amount);
          const response = await axios.post(`https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/wallet/add_wallet`, {
            user_id: User[0].id,
            wallet_id: User[0].wallet_id,
            transaction_type:'deposit',
            amount:parseFloat(amount),
          });
          if (response) {
            console.log(response);
            dispatch(deposit(parseFloat(amount)));
            if (history?.state?.from === '/CheckoutOrder') {
              navigate(-1); // Navigate back one step in the history
            }
          }
    
          setIsAddMoneyModalOpen(false);
        } catch (error) {
          console.log(error);
        }
      };
      
  useTop();
  useEffect(()=>{
    dispatch(fetchWalletBalance(User[0]));
  },[])

  return (
    <div>
       <div className="my-account-content">
            <section className="MyProfile_Wallet_Balance_Section">
              <div className="MyProfile_Wallet_Count_Div">
                <h3 className="MyProfile_Wallet_Count_Amount">
                 ${WalletBalance > 0 ? parseFloat(WalletBalance)?.toFixed(2):"0.00"} 
                </h3>
                <p className="MyProfile_Wallet_Count_Text">
                  Current FarmProduce Wallet Ballance
                </p>
              </div>
              <div className="MyProfile_Wallet_Btn_Div">
                {/* <button onClick={()=>navigate('/MyProfile/DebitMoney')}>
                  WithDraW
                </button> */}
                <button
                  className="MyProfile_Wallet_Btn"
                  onClick={() => setIsAddMoneyModalOpen(true)}
                >
                  <BsPlus size={30} />
                  Add Money to Wallet
                </button>
              </div>
            </section>
          </div>
          <AddMoneyModal
        isOpen={isAddMoneyModalOpen}
        onAddMoney={HandleAddMoneyClick}
        onCancel={() => setIsAddMoneyModalOpen(false)}
      />
    </div>
  )
}

export default FarmproduceWallet
