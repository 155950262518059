import React from "react";

function DefaultValues({ onAddMoney,selectedButton }) {
  const defaultAmounts = [100, 200, 500, 1000];
  return (
    <div>
      <p>Choose an amount:</p>
      {defaultAmounts.map((amount, index) => (
        <button
          key={index}
          onClick={() => onAddMoney(amount)}
          className={`AddMoneyDefaultValue_Button p-3 mr-3 ${
            selectedButton === amount ? "selected" : ""
          }`}
        >
          {amount}
        </button>
      ))}
    </div>
  );
}

export default DefaultValues;
