import React, { useState } from 'react';

function InputField({ onAddMoney ,inputValue}) {
  return (
    <>
      <input
        type="number"
        value={inputValue}
        maxLength={5}
        className='AddMoneyInputFeild_Input'
        onChange={(e) => onAddMoney(e.target.value)}
        placeholder="Enter amount"
      />
    </>
  );
}

export default InputField;

