// walletSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../API";

export const getPromoCodesFromDatabaseAsync = createAsyncThunk(
  "cart/getPromoCodesFromDatabase",
  async (Parameter, thunkAPI) => {
    // const response = axios.get('Cart/GetPromoCode');
    const response = axios.get('https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/get_promo_code/');
    console.log(response);
    return response.data
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    balance: 0,
    loading: false,
    error: null,
    cardDetails: [],
    promoCode:[]
  },
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    EmptyBalance: (state, action) => {
      state.balance = 0;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // Reducer functions to update the wallet state
    deposit: (state, action) => {
      state.balance += action.payload;
    },
    withdraw: (state, action) => {
      state.balance -= action.payload;
    },
    //card functions
    AddCardDetails: (state, action) => {

      const {cvc,focus,...otherDetails} = action.payload;
      // Check if the payload already exists in cardDetails
      const existingIndex = state.cardDetails.findIndex(
        (item) => item.number === otherDetails.number
      );

      // If the payload doesn't exist, add it to cardDetails
      if (existingIndex === -1) {
        state.cardDetails = [...state.cardDetails, otherDetails];
      }else{
        console.log('card already exist');
      }
    },
  },extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getPromoCodesFromDatabaseAsync.fulfilled, (state, action) => {
      // Add user to the state array
      console.log(action.payload)
    })
  },
});

// Export the reducer and actions
export const {
  deposit,
  withdraw,
  setBalance,
  setLoading,
  setError,
  EmptyBalance,
  AddCardDetails
} = walletSlice.actions;

export const selectBalance = (state) => state.wallet.balance;

// Export the reducer function to be added to the Redux store
export default walletSlice.reducer;
