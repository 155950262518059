import { createSlice } from '@reduxjs/toolkit';

const initialLocationStateMenu = () => {
    return JSON.parse(localStorage.getItem("UserLocation")) ? false : true;
  }

const LocalSlice = createSlice({
    name:'Local',
    initialState:{
        isDrawerOpen:false,
        isLocationMenuOpen:initialLocationStateMenu(),
    },
    reducers: {
        DrawerStateChangeTrue:(state,action) => {
          state.isDrawerOpen = true
        },
        DrawerStateChangeFalse:(state,action) => {
          state.isDrawerOpen = false
        },
        LocationMenuStateToFalse:(state,action) => {
            state.isLocationMenuOpen = false
        }
        ,
        LocationMenuStateToTrue:(state,action) => {
            state.isLocationMenuOpen = true
        },
    },
})

export const {DrawerStateChangeTrue,DrawerStateChangeFalse,LocationMenuStateToFalse,LocationMenuStateToTrue} = LocalSlice.actions;

export default LocalSlice.reducer;