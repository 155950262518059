import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "API";
import axios from "axios";
import { useSelector } from "react-redux";

const initialLocation = () => {
  return JSON.parse(localStorage.getItem("UserLocation")) || {};
};

export const fetchStoreLocation = createAsyncThunk(
  "auth/fetchStoreLocation",
  async (postcode, { getState }) => {
    try {
      // const response = await axios.get(`${Baseurl}Product/GetStoreDetailsWithPostalCode/${postcode}`);
      const response = await axios.get(
        `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/product/get_store_details_with_postal_code/${postcode}`
      );
      console.log(response);
      return response.data.results[0];
    } catch (error) {
      console.log("Error fetching store location:", error);
      throw error;
    }
  }
);

// const User = useSelector((state) => state.auth.user);
// console.log(User, "user");
export const fetchUserDetails = createAsyncThunk(
  "auth/fetchUserDetails",
  async (user, { getState }) => {
    console.log(user);
    console.log(user[0].id);
    try {
      const result = await axios.get(
        `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/user/get_user_profile_data/${user[0].id}`
      );
      console.log(result, "get user profile data");
      console.log(result.data.results);
      return result.data.results;
    } catch (error) {
      console.log("erro fetching user data", error);
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: [],
    location: initialLocation(),
    store: {},
    detailPageAdd: [],
    isAuthenticated: false,
    loading: false,
    error: null,
  },
  reducers: {
    login: (state, action) => {
      // const value = JSON.parse(localStorage.getItem('persist:cart'));
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      console.log(state);
      state.user = [];
      state.isAuthenticated = false;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setStoreLocation: (state, action) => {
      state.store = action.payload;
    },
    UpdateEmail: (state, action) => {
      const updatedUsers = state.user.map((user) => {
        return {
          ...user,
          email_id: action.payload,
        };
      });
      return {
        ...state,
        user: updatedUsers,
      };
    },
    detailUpdate: (state, action) => {
      state.detailPageAdd = action.payload;
      console.log(state.detailPageAdd);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStoreLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.store = action.payload;
      })
      .addCase(fetchStoreLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const {
  login,
  logout,
  setLocation,
  setStoreLocation,
  UpdateEmail,
  detailUpdate,
} = authSlice.actions;

export const selectUser = (state) => state.auth.user[0];
export const selectStore = (state) => state.auth.store;
export const selectLoading = (state) => state.auth.loading;
export const selectError = (state) => state.auth.error;

export default authSlice.reducer;
