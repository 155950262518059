// walletActions.js
import { Baseurl } from '../../API';
import { setBalance, setLoading, setError } from '../Reducers/WalletSlice';
import axios from 'axios';

export const fetchWalletBalance = (user) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // console.log(user);
    // const response = await axios.get(`${Baseurl}Wallet/getWallet/${user.id}`);
    const response = await axios.get(`https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/wallet/get_wallet/${user.id}`);
    console.log(response.data);
    const balance = response.data.results[0].amount; // Assuming the response contains the wallet balance
    dispatch(setBalance(balance));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
};





