import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import React, { createContext, lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingIndicator from "Components/LoadingIndicator";
import CachedFooter from "./Components/Footer/Footer";
import FarmproduceWallet from "Components/FarmproduceWallet";
import OrderDetailA from "Components/OrderDetailA";
import { useDispatch } from "react-redux";
import { fetchUserDetails } from "redux/Reducers/LoginSlice";

// import MyAddress from "Components/MyAddress";

const ChatBot = lazy(() => import("Components/ChatBot/ChatBot"));
const Home = lazy(() => import("./Home/Home"));
const ViewAllProducts = lazy(() => import("./ViewAllProducts/ViewAll"));
const MyProfile = lazy(() => import("./MyAccount/MyProfile"));
const DetailProduct = lazy(() => import("./Components/Detail/DetailPage"));
const TopNavigator = lazy(() => import("./Components/TopNavi/Navigator"));
const TransactionPage = lazy(() => import("./Components/TransactionPage"));
const TapPage = lazy(() => import("./Components/TapPage/TapPage"));
const LocationInput = lazy(() => import("./Components/LocationInput/Location"));
const TAndC = lazy(() => import("./Components/T&C/T&C"));
const NotFound = lazy(() => import("./Components/404/EmptyPage"));
const SignUp = lazy(() => import("./Components/RegisterForm/RegisterForm"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const BottomNav = lazy(() => import("./Components/BottomNav/BottomNav"));
const CartPage = lazy(() => import("./Components/CartPage/CartPage"));
const CardPayment = lazy(() => import("./Components/CardPayment"));
const DebitMoney = lazy(() => import("./Components/DebitMoney"));
const PaymentPage = lazy(() => import("./Components/PaymentPage"));
const UPI = lazy(() => import("./Components/UPI"));
const Wallet = lazy(() => import("./Components/Wallet"));
const OrderPage = lazy(() => import("./Components/OrderPage"));
const OrderDetail = lazy(() => import("./Components/OrderDetail"));
const Card = lazy(() => import("Components/Card"));
const New = lazy(() => import("Components/New"));
const DealsPage = lazy(() => import("./Components/DealsPage/DealsPage"));
const EmptyCart = lazy(() => import("Components/EmptyCart"));
const CheckoutOrder = lazy(() => import("Components/CheckoutOrder"));
const OrderSuccessfull = lazy(() => import("Components/OrderSuccessfull"));
const ProductViewPage = lazy(() => import("Components/ProductDetailsPage"));
const Transactiontable = lazy(() => import("Components/TransactionPage"));
const Profile = lazy(() => import("Components/Profile"));
const EditProfile = lazy(() => import("../src/Components/EditProfile"));
const MyAddress = lazy(() => import("../src/Components/MyAddress"));

function App() {
  const dispatch = useDispatch();
  const isLocationMenuOpen = useSelector(
    (state) => state.Local.isLocationMenuOpen
  );
  const User = useSelector((state) => state.auth.user);

  useEffect(() => {
    console.log(User);
    if (User) {
      dispatch(fetchUserDetails(User));
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                width: "100vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <LoadingIndicator />
            </div>
          }
        >
          {isLocationMenuOpen && <LocationInput />}
          <TopNavigator />
          <ChatBot />
          <div
            style={{
              minHeight: "100vh-6.25rem",
              maxWidth: "1650px",
              marginInline: "auto",
            }}
          >
            <Routes>
              <Route path="*" element={<h1>Not Found</h1>} />
              <Route path="/" element={<Home />} />
              <Route path="/AllProducts" element={<ViewAllProducts />} />
              <Route path="/MyProfile" element={<MyProfile />}>
                {/* <Route path="" element={<TransactionPage />} /> */}
                <Route
                  path="FarmproduceWallet"
                  element={<FarmproduceWallet />}
                />
                <Route path="TransactionTable" element={<Transactiontable />} />
                <Route path="OrderPage" element={<OrderPage />} />
                <Route path="Profile" element={<Profile />} />
                <Route path="MyAddress" element={<MyAddress />} />
                <Route path="Profile/EditProfile" element={<EditProfile />} />
                <Route path="CardPayment" element={<CardPayment />} />
                <Route path="DebitMoney" element={<DebitMoney />} />
                <Route
                  path="OrderDetail/:Order_id"
                  element={<OrderDetailA />}
                />
              </Route>
              <Route path="/New" element={<New />} />
              <Route path="/Detail" element={<DetailProduct />} />
              <Route path="/PaymentPage" element={<PaymentPage />} end>
                <Route path="UPI" element={<UPI />} />
                <Route path="Card" element={<Card />} />
                <Route index element={<Wallet />} />
              </Route>

              {/* <Route path="/OrderDetail/:Order_id" element={<OrderDetail />} /> */}

              <Route path="/TapPage" element={<TapPage />} />
              <Route path="/Location" element={<LocationInput />} />
              <Route path="/CartPage" element={<CartPage />} />
              <Route path="/CheckoutOrder" element={<CheckoutOrder />} />
              <Route path="/T&C" element={<TAndC />} />
              <Route path="/NotFound" element={<NotFound />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/ProductView" element={<DealsPage />} />
              <Route path="/ProductDetailsPage" element={<ProductViewPage />} />
              <Route path="/EmptyCart" element={<EmptyCart />} />
              <Route path="/OrderSuccessfull" element={<OrderSuccessfull />} />
            </Routes>
          </div>
          <CachedFooter />
          {/* <BottomNav setHideCarousel={setHideCarousel} /> */}
        </Suspense>
      </Router>
    </>
  );
}

export default App;
