export const NameValidation = (name) => {
  const namePattern = /^[A-Za-z\s'-]+$/;
  const isValidName = namePattern.test(name);
  return isValidName;
};


export const PriceValidate = (price) => {
  const namePattern = /^\d+(\.\d{0,2})?$/;
  const isValid = namePattern.test(price);
  console.log(price)
  console.log(isValid );
  return isValid;
}
