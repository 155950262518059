import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Baseurl } from "../API";
import "../Styles/OrderDetails.css";
import HorizontalStepper from "./HorizontalStepper";
import StarRating from "./StartRating";
import BreadCrump from "./BreadCrump";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import VerticalStepper from "./VerticalStepper";
import { FaArrowLeft } from "react-icons/fa6";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import OrderStepper from "./NewStepper";
import { PiNotebookBold } from "react-icons/pi";
import { PiPackageDuotone } from "react-icons/pi";
import { PiTruck } from "react-icons/pi";
import { PiHandshakeDuotone } from "react-icons/pi";

const OrderDetailA = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { Order_id } = useParams();

  const options = { day: "numeric", month: "long", year: "numeric" };
  const navigate = useNavigate();

  const promotion = () => {
    console.log(
      "log ~ promotion ~ orderDetails?.promotion_offer:",
      orderDetails[0]?.promotion_offer
    );
    if (orderDetails[0]?.promotion_offer) {
      if (orderDetails[0]?.promotion_type === "percentage") {
        return (
          parseFloat(orderDetails[0]?.tot_price) *
          (orderDetails[0]?.promotion_offer / 100)
        );
      } else {
        return orderDetails[0]?.promotion_offer;
      }
    }
    return 0;
  };
  const steps = [
    {
      icon: <PiNotebookBold style={{ height: "2rem", width: "2rem" }} />,
      step: "Ordered",
      status: true,
      label: "step 1",
    },
    {
      icon: <PiPackageDuotone style={{ height: "2rem", width: "2rem" }} />,
      step: "Dispatched",
      status: false,
      label: "step 2",
    },
    {
      icon: <PiHandshakeDuotone style={{ height: "2rem", width: "2rem" }} />,
      step: "Delivered",
      status: false,
      label: "step 3",
    },
  ]; // Array of strings
  const priceSum = (price) => {
    const priceValue = parseInt(price);
    const fractionalPart = (price % 1).toFixed(2).substring(2);

    return priceValue + "." + fractionalPart;
  };

  // useLayoutEffect(() => {
  //   axios
  //     .get(`${Baseurl}Order/getOrderDetails/${Order_id}`)
  //     .then((response) => {
  //       setOrderDetails(response.data.results);
  //       console.log(response.data.results);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      // .get(`${Baseurl}Order/getOrderDetails/${Order_id}`)
      .get(
        `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/order/get_order_details/${Order_id}`
      )
      .then((response) => {
        console.log(response);
        setOrderDetails(response.data.results);
        if (response.status === 201) {
          setIsLoading(false);
        }
        console.log(response.data.results);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  const handleRatingChange = (newRating) => {
    console.log(`User rated: ${newRating} stars`);
    // You can save the rating to your state or perform other actions here
  };
  console.log(orderDetails);

  const CalculateProductPrice = (product) => {
    console.log(product, "running");
    return (
      (parseFloat(product.price) -
        parseFloat(product.price) *
          (parseFloat(product.discounted_price) / 100)) *
      product.quantity
    )?.toFixed(2);
  };
  const CalculateMeatProductPrice = (product) => {
    // console.log((product.quantity * product.weight))
    return (
      parseFloat(product.discounted_price) *
      (parseFloat(product.Weight) * parseFloat(product.quantity))
    ).toFixed(2);
    // ((parseFloat(product.price) - parseFloat(product.price) * (parseFloat(product.discounted_price) / 100))*(parseFloat(product.Weight) * product.quantity))?.toFixed(2)
  };

  // const promotion = () => {
  //   console.log(state);

  //   if (state?.code) {
  //     if(state.code.offer_type=="percentage"){
  //       return parseFloat(selectedPrice) * (state?.code.offer / 100);
  //     }
  //     else if(state.code.offer_type=="flat") return state?.code.offer
  //   }

  //   return 0;
  // };

  // const Total = ((parseFloat(selectedPrice) + state?.tax +state?.deliveryCharge) -promotion())?.toFixed(2);

  return (
    <>
      <div className="OrderDetail_OverallContainer">
        {/* <p style={{ marginLeft: "20vw"}}><BreadCrump/></p> */}
        <div className="OrderDetail_Container">
          <div style={{ border: "1px solid #E4E7E9" }}>
            <h3
              style={{
                fontSize: "1.5rem",
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <FaArrowLeft
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/MyProfile/OrderPage");
                }}
              />
              Order Details
            </h3>
          </div>
          <Box
            mt={2}
            sx={{
              padding: "5px",
              border: "1px solid #D55D59",
              backgroundColor: "#FAEBEA",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              #{Order_id}
              <Typography sx={{ gap: "1rem", display: "flex" }}>
                <p>{orderDetails[0]?.orders?.length}products</p>
                <p>
                  | Order placed in
                  {new Date(
                    orderDetails &&
                      orderDetails[0] &&
                      orderDetails[0]?.order_date
                  ).toLocaleDateString("en-US", options)}
                </p>
              </Typography>
            </Typography>

            <Typography sx={{ fontWeight: 600, color: "#D55D59" }}>
              ${parseFloat(orderDetails[0]?.total_price)?.toFixed(2)}
            </Typography>
          </Box>
          <header className={`OrderDetail_Header`}>
            <p>
              <p>
                Order expected arrival
                {new Date(
                  orderDetails && orderDetails[0] && orderDetails[0]?.order_date
                )?.toLocaleDateString("en-US", options)}
              </p>
            </p>
          </header>
          <OrderStepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={steps}
          />
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div style={{ marginTop: "20px" }}>
              <div>
                <h4>product({orderDetails[0]?.orders?.length})</h4>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Products</TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                          Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                          Price
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                          Quantity
                        </TableCell>

                        <TableCell sx={{ fontWeight: 600 }} align="right">
                          Sub-Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails[0]?.orders?.map((orderItem, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              orderItem.orderitem_status === "Canceled"
                                ? "#B5B5B5"
                                : "inherit",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ alignItems: "center", display: "flex" }}
                          >
                            <img
                              src={orderItem.image_data}
                              alt={orderItem.product_name}
                              width="70"
                              height="70"
                              style={{ marginRight: "10px" }}
                            />
                            <p>{orderItem.product_name}</p>
                          </TableCell>
                          <TableCell align="right">
                            {orderItem.orderitem_status}
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {parseFloat(orderItem.discount) > 0 ? (
                              <>
                                <p> $ {priceSum(orderItem.discounted_price)}</p>{" "}
                              </>
                            ) : (
                              <p> $ {priceSum(orderItem.actual_price)}</p>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {orderItem.quantity}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: 600 }}>
                            $ {parseFloat(orderItem.price)?.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <section className={`OrderDetail_Information_Section`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <h4>Payment info</h4>
                    <p>Payment Method</p>
                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0]?.payment_method}
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Price</p>
                      <p style={{ fontWeight: 600 }}>
                        {parseFloat(orderDetails[0]?.tot_price)?.toFixed(2)}$
                      </p>
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Shipping fee</p>
                      <p style={{ fontWeight: 600 }}>
                        {orderDetails[0]?.delivery_charge?.toFixed(2)}$
                      </p>
                    </p>
                    {/* <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>total tax</p>
                        <p style={{ fontWeight: 600 }}>
                          {item.total_tax.toFixed(2)}$
                        </p>
                      </p> */}
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Promotion</p>
                      <p style={{ fontWeight: 600 }}>
                        {promotion()?.toFixed(2)}$
                      </p>
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Total</p>
                      <p style={{ fontWeight: 600 }}>
                        {parseFloat(orderDetails[0]?.total_price)?.toFixed(2)}$
                      </p>
                    </p>
                  </div>
                </section>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <h5>Shipping Address</h5>
                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0]?.user_name}
                    </p>
                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0]?.address_line_2}
                    </p>
                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0]?.address_line_1}
                    </p>
                    <p>
                      {orderDetails && orderDetails[0] && orderDetails[0]?.city}
                    </p>
                  </div>
                  {/* <div style={{ width: "50%" }}>
                    <h5>Shipping Address</h5>

                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0].user_name}
                    </p>
                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0].address_line_2}
                    </p>
                    <p>
                      {orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0].address_line_1}
                    </p>
                    <p>
                      {orderDetails && orderDetails[0] && orderDetails[0].city}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              display: "grid",
              inset: "50%",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <h4>Rate This Order</h4>
            <StarRating
              totalStars={5}
              initialRating={5}
              onRatingChange={handleRatingChange}
            />
          </div>
        </div>

        {/* <div style={{display:"grid",inset:"50%",textAlign:"center",marginTop:"20px"}}>
        <h4>Rate This Order</h4>
        <StarRating
          totalStars={5}
          initialRating={5}
          onRatingChange={handleRatingChange}
        />
      </div> */}
      </div>
    </>
  );
};

export default OrderDetailA;
