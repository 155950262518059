import React from "react";
import { Triangle } from "react-loader-spinner";

function LoadingIndicator() {
  return (
    <div
      className="loading-indicator"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* {console.log('am here')} */}
      <Triangle
        height="80"
        width="80"
        color="#CA342F"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
}

export default LoadingIndicator;
