import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '../Reducers/cartSlice';
import authSlice from '../Reducers/LoginSlice';
import WalletSlice from '../Reducers/WalletSlice';
import LocalSlice from 'redux/Reducers/LocalSlice';
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const cartPersistConfig = {
  key: 'cart', // Use a unique key for the 'cart' slice
  storage: storage,
  whitelist: ['cartItems','cartPrice','selectedItem','selectedPrice'], // Specify the part of the 'cart' slice to persist
};

const userPersistConfig = {
  key: 'auth', // Use a unique key for the 'cart' slice
  storage: storage,
  whitelist: ['user','location','store'], // Specify the part of the 'cart' slice to persist
};

const walletPersistConfig = {
  key: 'wallet',
  storage: storage,
  whitelist: ['balance'],
}

const persistedCartReducer = persistReducer(cartPersistConfig, cartReducer);

const persistedUserReducer = persistReducer(userPersistConfig, authSlice);

const persistedWalletReducer = persistReducer(walletPersistConfig, WalletSlice);


const store = configureStore({
  reducer: {
    cart: persistedCartReducer,
    auth: persistedUserReducer,
    wallet: persistedWalletReducer,
    Local:LocalSlice
  },
  middleware:(getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false, // Disable serializable check
  }),
});


const persistor = persistStore(store);

export { store, persistor };


