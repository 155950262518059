import React, { useEffect, useState } from "react";
import "../Styles/VerticalStepper.css";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import { Baseurl } from "API";
import { useSelector } from "react-redux";

const VerticalStepper = ({ currentStep, setCurrentStep, selectedOption,SelectedAddress, steps,complete }) => {
  const user = useSelector((state) => state.auth.user[0])

  // console.log(steps);
  return (
    <div className="vertical-stepper">
      <div className="steps">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index === currentStep ? "active" : ""}`}
            onClick={() => {
              if (currentStep > 0 && currentStep !== 2) {
                setCurrentStep(index);
              }
            }}
          >
            <div className="step-checkmark">
              {index < currentStep ? (
                <span
                  style={{
                    backgroundColor: "#28a745",
                    height: "inherit",
                    width: "inherit",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FaCheck color="white" size="0.9375rem" />
                </span>
              ) : index === currentStep ? (
                <span></span>
              ) : (
                <span className={`VerticalStepper_Step_Checkmark_In`}></span>
              )}
            </div>       
            <div className="step-label">
              <p style={{ fontSize: "0.8rem" }}>{step.step}</p>
              {index < currentStep && index === 0 ? <p style={{display:"flex",flexDirection:"column"}}>address  <span style={{fontWeight:800}}>{SelectedAddress && `${SelectedAddress.address_line_2}`}</span></p> : <p>{step.label}</p>}
              {index < currentStep && index === 0 && (
                <button className={`VerticalStepper_Change_Button`} onChange={()=>setCurrentStep(0)}>Change</button>
              )}
              <p style={{ fontSize: "0.9rem", color: "#28a745" }}>
                {index < currentStep ? "Complete" : ""}
              </p>
            </div>
            {index < currentStep ? (
              <div className="step-line active"></div>
            ) : (
              index !== steps.length - 1 && <div className="step-line"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalStepper;
