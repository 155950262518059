import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../API";

export const saveCartItemsToDatabaseAsync = createAsyncThunk(
  "cart/saveCartItemsToDatabase",
  async (cartItems, { getState, dispatch }) => {
    console.log(cartItems);
    const state = getState();
    const user = state.auth.user[0];
    const existingItem = state.cart.cartItems.find((item) => {
      return item.product_id === cartItems.product_id;
    });

    if (user) {
      dispatch(loadingStateChangeTrue(cartItems.product_id));
      if (!existingItem) {
        try {
          const response = await axios.post(
            `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/add-cart`,
            {
              user_id: user.id,
              product_id: cartItems.product_id,
              product_name: cartItems.name,
              price: cartItems.price,
              quantity: 1,
            }
          );
          console.log(cartItems);
          dispatch(addToCart(cartItems));
          return response.data;
        } catch (error) {
          console.log(error, "cart error cartSlice");
        } finally {
          dispatch(loadingStateChangeFalse(cartItems.product_id));
        }
      } else {
        try {
          // const response = await axios.put(`${Baseurl}Cart/UpdateCount`, {
          //   user_id: user.id,
          //   product_id: cartItems.product_id,
          //   name: cartItems.name,
          //   price: cartItems.price,
          //   quantity: existingItem.quantity + 1,
          // });
          const response = await axios.put(
            `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/update_count`,
            {
              user_id: user.id,
              product_id: cartItems.product_id,
              product_name: cartItems.name,
              price: cartItems.price,
              quantity: existingItem.quantity + 1,
            }
          );
          dispatch(addToCart(cartItems));
          console.log(response);
          return response.data;
        } catch (error) {
        } finally {
          dispatch(loadingStateChangeFalse(cartItems.product_id));
        }
      }
    } else {
      dispatch(addToCart(cartItems));
      dispatch(loadingStateChangeFalse(cartItems.product_id));

      return null;
    }
  }
);

export const addCartItemFromProductPageAsync = createAsyncThunk(
  "cart/addCartItemFromProductPage",
  async (payload, { getState, dispatch }) => {
    const cartItems = payload.cartItems;
    console.log("~~-------~~cartItems:", cartItems);
    const quantity = payload.quantity;
    const state = getState();
    const user = state.auth.user[0];
    const existingItem = state.cart.cartItems.find((item) => {
      return item.product_id === cartItems.product_id;
    });
    console.log(user);
    if (user) {
      dispatch(loadingStateChangeTrue(cartItems.product_id));
      if (!existingItem) {
        console.log({
          user_id: user.id,
          product_id: cartItems.product_id,
          product_name: cartItems.name,
          price: cartItems.price,
          quantity,
          weight: cartItems.weight,
          cleaning_type: cartItems.cleaning_type,
          cutting_type: cartItems.cutting_type,
          special_cutting: cartItems.special_cutting,
        });
        try {
          console.log(cartItems, "add cart post");
          // const response = await axios.post(`${Baseurl}Cart/AddCart`, {
          //   user_id: user.id,
          //   product_id: cartItems.product_id,
          //   name: cartItems.name,
          //   price: cartItems.price,
          //   quantity,
          //   weight: cartItems.weight,
          //   cleaning_type: cartItems.cleaning_type,
          //   cutting_type: cartItems.cutting_type,
          //   special_cutting: cartItems.special_cutting,
          // });

          const response = await axios.post(
            `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/add-cart`,
            {
              user_id: user.id,
              product_id: cartItems.product_id,
              product_name: cartItems.name,
              price: cartItems.price,
              quantity,
              weight: cartItems.weight,
              cleaning_type: cartItems.cleaning_type,
              cutting_type: cartItems.cutting_type,
              special_cutting: cartItems.special_cutting,
            }
          );
          console.log(response);
          dispatch(addToCartFromProductPage({ cartItems, quantity }));
          return response.data;
        } catch (error) {
        } finally {
          dispatch(loadingStateChangeFalse(cartItems.product_id));
        }
      } else {
        try {
          console.log(cartItems, "update count put");

          // const response = await axios.put(`${Baseurl}Cart/UpdateCount`, {
          //   user_id: user.id,
          //   product_id: cartItems.product_id,
          //   name: cartItems.name,
          //   price: cartItems.price,
          //   quantity,
          //   weight: cartItems.weight,
          //   cleaning_type: cartItems.cleaning_type,
          //   cutting_type: cartItems.cutting_type,
          //   special_cutting: cartItems.special_cutting,
          // });
          const response = await axios.put(
            `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/update_count`,
            {
              user_id: user.id,
              product_id: cartItems.product_id,
              product_name: cartItems.name,
              price: cartItems.price,
              quantity,
              weight: cartItems.weight,
              cleaning_type: cartItems.cleaning_type,
              cutting_type: cartItems.cutting_type,
              special_cutting: cartItems.special_cutting,
            }
          );
          dispatch(addToCartFromProductPage({ cartItems, quantity }));
          console.log(response);
          return response.data;
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(loadingStateChangeFalse(cartItems.product_id));
        }
      }
    } else {
      dispatch(addToCartFromProductPage({ cartItems, quantity }));
      return null;
    }
  }
);

export const getCartItemFromDatabaseAsync = createAsyncThunk(
  "cart/getCartItemFromDatabase",
  async (isThatCartPage, { getState }) => {
    const state = getState();
    const user = state.auth.user[0];
    const cartItem = state.cart.cartItems;
    const isCartPage = isThatCartPage ? true : false;

    console.log("thunk function ~ cartItem:", cartItem);
    if (user) {
      try {
        // const response = await axios.post(
        //   `${Baseurl}Cart/getCartData/${user.id}`,
        //   cartItem
        // );
        const response = await axios.post(
          `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/get_cart_data/${user.id}`,
          { cartItem: cartItem, isCartPage }
        );

        console.log(
          response.data,
          "response cart slice get cart data````````&&&&"
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    } else {
      return null;
    }
  }
);

export const removeCartItems = createAsyncThunk(
  "cart/removeCartItems",
  async (cartItems, { getState, dispatch }) => {
    const state = getState();
    const user = state.auth.user[0];
    if (user) {
      const existing = state.cart.cartItems.find((item) => {
        return item.product_id === cartItems.product_id;
      });
      if (existing) {
        try {
          // const response = await axios.put(`${Baseurl}Cart/ModifyCart`, {
          //   user_id: user.id,
          //   product_id: existing.product_id,
          //   item_status: "Removed",
          // });
          const response = await axios.put(
            `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/modify_cart`,
            {
              user_id: user.id,
              product_id: existing.product_id,
              item_status: "Removed",
            }
          );
          dispatch(removeCart(cartItems));
          console.log(response);
          return response.data;
        } catch (error) {
          console.log(error);
        }
      } else {
        return null;
      }
    } else {
      dispatch(removeCart(cartItems));
    }
  }
);

export const savePromoCodeToReduxAsync = createAsyncThunk(
  "cart/savePromoCodeToRedux",
  async (cartItem, { getState }) => {
    const state = getState();
    const user = state.auth.user[0];

    if (user) {
      try {
        const response = await axios.post(`${Baseurl}Cart/Get`, {
          user_id: user.id,
        });
        return response.data;
      } catch (error) {}
    }
  }
);

export const modifyCartItemsAsync = createAsyncThunk(
  "cart/modifyCartItems",
  async (cartItems, { getState, dispatch }) => {
    const state = getState();
    const user = state.auth.user[0];
    dispatch(loadingStateChangeTrue(cartItems.product_id));
    if (user) {
      const existingItem = state.cart.cartItems.find((item) => {
        return item.product_id === cartItems.product_id;
      });
      if (existingItem) {
        if (existingItem.quantity === 1) {
          try {
            // const response = await axios.put(`${Baseurl}Cart/ModifyCart`, {
            //   user_id: user.id,
            //   product_id: existingItem.product_id,
            //   item_status: "removed",
            // });
            const response = await axios.put(
              `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/modify_cart`,
              {
                user_id: user.id,
                product_id: existingItem.product_id,
                item_status: "removed",
              }
            );
            dispatch(removeToCart(cartItems));
            console.log(response);
            return response.data;
          } catch (error) {
            console.log(error);
          } finally {
            dispatch(loadingStateChangeFalse(cartItems.product_id));
          }
        } else {
          try {
            // const response = await axios.put(`${Baseurl}Cart/UpdateCount`, {
            //   user_id: user.id,
            //   product_id: cartItems.product_id,
            //   name: cartItems.name,
            //   price: cartItems.price,
            //   quantity: existingItem.quantity - 1,
            // });
            const response = await axios.put(
              `https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/cart/update_count`,
              {
                user_id: user.id,
                product_id: cartItems.product_id,
                product_name: cartItems.name,
                price: cartItems.price,
                quantity: existingItem.quantity - 1,
              }
            );
            dispatch(removeToCart(cartItems));
            console.log(response);
            return response.data;
          } catch (error) {
            console.log(error);
          } finally {
            dispatch(loadingStateChangeFalse(cartItems.product_id));
          }
        }
      }
    } else {
      dispatch(removeToCart(cartItems));
      dispatch(loadingStateChangeFalse(cartItems.product_id));
      return null;
    }
  }
);
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    cartAlert: { open: false, product_name: "" },
    cartPrice: 0,
    cartQuantity: 0,
    selectedItem: [],
    selectedPrice: 0,
    selectedQuantity: 0,
    BuyNow: {},
    isLoading: {},
  },
  reducers: {
    updateAlert: (state, action) => {
      state.cartAlert = action.payload;
    },
    sample: (state, action) => {
      console.log("working");
    },
    addToBuyNow: (state, action) => {
      const Item = action.payload.item;
      const quantity = action.payload.quantity;
      return {
        ...state,
        BuyNow: { ...Item, quantity },
      };
    },
    loadingStateChangeTrue: (state, action) => {
      const id = action.payload;
      return {
        ...state,
        isLoading: { ...state.isLoading, [id]: true },
      };
    },
    loadingStateChangeFalse: (state, action) => {
      const idToRemove = action.payload;
      // Destructure the isLoading object and omit the property with the idToRemove
      const { [idToRemove]: _, ...updatedIsLoading } = state.isLoading;
      return {
        ...state,
        isLoading: updatedIsLoading,
      };
    },
    CalculateCartPrice: (state, action) => {
      state.cartPrice = state.cartItems.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );
    },
    ChangeSelectedItemForCheckout: (state, action) => {
      const product = action.payload.product;
      const check = action.payload.check;

      if (!check) {
        state.selectedItem = [
          ...state.selectedItem.filter(
            (cartItem) => cartItem.product_id !== product.product_id
          ),
        ];
        state.selectedPrice = state.selectedItem.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      } else {
        state.selectedItem = [
          ...state.selectedItem,
          state.cartItems.find(
            (cartItem) => cartItem.product_id === product.product_id
          ),
        ];

        state.selectedPrice = state.selectedItem.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      }
    },
    SelectAllItemsFromCart: (state, action) => {
      state.selectedItem = [...state.cartItems];
      state.selectedPrice = state.cartPrice;
    },
    unSelectAllItemsFromCart: (state, action) => {
      state.selectedItem = [];
      state.selectedPrice = 0;
    },
    TotalSelectedItem: (state, action) => {
      // Calculate the total price of selected items in the cart
      state.selectedPrice = state.cartItems.reduce((total, item) => {
        if (item.selected) {
          return (
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity
          );
        }
        return total;
      }, 0);
    },

    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.product_id === newItem.product_id
      );
      const existingItemIndexFromSelected = state.cartItems.findIndex(
        (item) => item.product_id === newItem.product_id
      );

      if (existingItemIndexFromSelected === -1) {
        state.selectedItem = [
          ...state.selectedItem,
          { ...newItem, quantity: 1 },
        ];
      } else {
        state.selectedItem = state.selectedItem.map((item, index) => {
          if (index === existingItemIndexFromSelected) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        });
      }

      if (existingItemIndex === -1) {
        // Item doesn't exist in the cart, so add it
        state.cartItems = [...state.cartItems, { ...newItem, quantity: 1 }];
      } else {
        // Item already exists in the cart, increase the quantity
        state.cartItems = state.cartItems.map((item, index) => {
          if (index === existingItemIndex) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        });
      }

      // Calculate the total price of all items in the cart
      state.cartPrice = state.cartItems.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );

      // Calculate the total price of all items in the cart
      state.selectedPrice = state.selectedItem.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );
    },
    addToCartFromProductPage: (state, action) => {
      const newItem = action.payload.cartItems;
      const weight = action.payload.cartItems.weight;
      const special_cutting = action.payload.cartItems.special_cutting;
      const cleaning_type = action.payload.cartItems.cleaning_type;
      const cutting_type = action.payload.cartItems.cutting_type;
      const quantity = action.payload.quantity;
      console.log(action.payload);
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.product_id === newItem.product_id
      );
      const existingItemIndexFromSelected = state.cartItems.findIndex(
        (item) => item.product_id === newItem.product_id
      );

      if (existingItemIndexFromSelected === -1) {
        state.selectedItem = [...state.selectedItem, { ...newItem, quantity }];
      } else {
        state.selectedItem = state.selectedItem.map((item, index) => {
          if (index === existingItemIndexFromSelected) {
            return {
              ...item,
              quantity,
              weight,
              special_cutting,
              cleaning_type,
              cutting_type,
            };
          }
          return item;
        });
      }

      if (existingItemIndex === -1) {
        // Item doesn't exist in the cart, so add it
        state.cartItems = [...state.cartItems, { ...newItem, quantity }];
      } else {
        // Item already exists in the cart, increase the quantity
        state.cartItems = state.cartItems.map((item, index) => {
          if (index === existingItemIndex) {
            return {
              ...item,
              quantity,
              weight,
              special_cutting,
              cleaning_type,
              cutting_type,
            };
          }
          return item;
        });
      }

      // Calculate the total price of all items in the cart
      state.cartPrice = state.cartItems.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );

      // Calculate the total price of all items in the cart
      state.selectedPrice = state.selectedItem.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );
    },

    removeCart: (state, action) => {
      const rmvItem = state.cartItems.find(
        (item) => item.product_id === action.payload.product_id
      );
      const rmvItemSelected = state.selectedItem.find(
        (item) => item.product_id === action.payload.product_id
      );
      if (rmvItemSelected) {
        state.selectedItem = state.selectedItem.filter(
          (item) => item.product_id !== action.payload.product_id
        );
        state.selectedPrice = state.selectedItem.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      }
      if (rmvItem) {
        state.cartItems = state.cartItems.filter(
          (item) => item.product_id !== action.payload.product_id
        );
        state.cartPrice = state.cartItems.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      }
    },

    removeToCart: (state, action) => {
      const itemToRemove = state.cartItems.find(
        (item) => item.product_id === action.payload.product_id
      );
      const itemToRemovefromSelected = state.selectedItem.find(
        (item) => item.product_id === action.payload.product_id
      );

      if (itemToRemovefromSelected) {
        if (itemToRemovefromSelected.quantity > 1) {
          state.selectedItem = state.selectedItem.map((item) => {
            if (item.product_id === action.payload.product_id) {
              return {
                ...item,
                quantity: item.quantity - 1,
              };
            }
            return item;
          });
        } else {
          state.selectedItem = state.selectedItem.filter(
            (item) => item.product_id !== action.payload.product_id
          );
        }

        // Recalculate the total price of all items in the cart
        state.selectedPrice = state.selectedItem.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      }

      if (itemToRemove) {
        if (itemToRemove.quantity > 1) {
          // If the item has a quantity greater than 1, decrease the quantity
          state.cartItems = state.cartItems.map((item) => {
            if (item.product_id === action.payload.product_id) {
              return {
                ...item,
                quantity: item.quantity - 1,
              };
            }
            return item;
          });
        } else {
          // If the item has a quantity of 1, remove it from the cart
          state.cartItems = state.cartItems.filter(
            (item) => item.product_id !== action.payload.product_id
          );
        }

        // Recalculate the total price of all items in the cart
        state.cartPrice = state.cartItems.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      }
    },
    removeOrderedItemsFromCart: (state, action) => {
      state.cartItems = [
        ...state.cartItems.filter(
          (cartItem) =>
            !state.selectedItem.some(
              (SelectedItem) => cartItem.product_id === SelectedItem.product_id
            )
        ),
      ];
      state.selectedItem = [];
      state.selectedPrice = 0;
      state.cartPrice = state.cartItems.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );
    },
    clearCartItems: (state, action) => {
      state.cartItems = [];
      state.cartPrice = 0;
    },
    clearSelectedItems: (state, action) => {
      state.selectedItem = [];
      state.selectedPrice = 0;
    },
    calculateSelectedCartItemPrice: (state, action) => {
      state.selectedPrice = state.selectedItem.reduce(
        (total, item) =>
          total +
          parseFloat(
            (item.price - item.price * (item.discount / 100)).toFixed(2)
          ) *
            (item.subcategory === "MEATSTORE" ? item.weight : 1) *
            item.quantity,
        0
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCartItemsToDatabaseAsync.fulfilled, (state, action) => {
        // if(!action.payload){
        //   const existingItem = state.cartItems.find((item) =>{
        //     return item.product_id === action.payload.product_id});
        //     if(!existingItem){
        //     state.cartItems = [...state.cartItems, {...action.payload,quantity:1}];
        //     return;
        //     }
        //     const existingQuantity = state.cartItems.find((cart) => cart.product_id === action.payload.product_id)[0].quantity;
        // }
        // Handle the response from saving cart items to the database if needed
      })
      .addCase(modifyCartItemsAsync.fulfilled, (state, action) => {
        console.log("modified");
        sample();
        // Handle the response from modifying cart items if needed
      })
      .addCase(getCartItemFromDatabaseAsync.fulfilled, (state, action) => {
        console.log(action.payload);
        state.cartItems = action.payload.ArrayFinal;
        state.selectedItem = action.payload.ArrayFinal;
        state.cartPrice = action.payload.ArrayFinal.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
        state.selectedPrice = action.payload.ArrayFinal.reduce(
          (total, item) =>
            total +
            parseFloat(
              (item.price - item.price * (item.discount / 100)).toFixed(2)
            ) *
              (item.subcategory === "MEATSTORE" ? item.weight : 1) *
              item.quantity,
          0
        );
      })
      .addCase(getCartItemFromDatabaseAsync.rejected, (state, action) => {});

    builder.addCase(
      addCartItemFromProductPageAsync.fulfilled,
      (state, action) => {
        console.log(action);
      }
    );
  },
});

export const {
  addToCart,
  updateAlert,
  removeToCart,
  removeCart,
  clearCartItems,
  ChangeSelectedItemForCheckout,
  SelectAllItemsFromCart,
  unSelectAllItemsFromCart,
  calculateSelectedCartItemPrice,
  clearSelectedItems,
  removeOrderedItemsFromCart,
  sample,
  loadingStateChangeTrue,
  loadingStateChangeFalse,
  addToCartFromProductPage,
} = cartSlice.actions;
export default cartSlice.reducer;
