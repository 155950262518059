import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Paper,
  Box,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { styled } from "@mui/system";

import { ReactComponent as Dot } from "../assets/images/Dot.svg";

// Custom step icon component
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#DC3545",
      // width: "90%",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#DC3545",
      // width: "90%",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    // width: "90%",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function IconTickCircle(props) {
  return (
    <svg fill="none" viewBox="0 0 15 15" height="2em" width="2em" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.072 3.21l4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <Box
      sx={{
        color: completed || active ? "#CA342F" : "rgba(0, 0, 0, 0.38)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
      }}
    >
      {completed || active ? <IconTickCircle /> : <Dot />}
    </Box>
  );
};

const OrderStepper = ({ currentStep, setCurrentStep, steps }) => {
  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={currentStep}
        connector={<ColorlibConnector />}
        sx={{ color: "#CA342F", width: "70%" }}
      >
        {steps.map((label, index) => (
          <Step key={label} active={label.status} sx={{ color: "#CA342F" }}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              // slotProps={{ label: { backgroundcolor: "#CA342F" } }}
            >
              <Typography sx={{ color: "#CA342F" }}>{label.icon}</Typography>
              <Typography>{label.step}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* <Button
        variant="outlined"
        sx={{
          borderColor: "#CA342F",
          borderRadius: "5px",
          color: "#CA342F",
          ":hover": {
            borderColor: "#CA342F",
            color: "#CA342F",
          },
        }}
      >
        Order Activity
      </Button> */}
    </Box>
  );
};

export default OrderStepper;
