import React, { useEffect, useMemo, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { BsPhone, BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import "./Footer.css";
import  Axios  from "axios";
import { Baseurl } from "API";
import { useLocation } from "react-router-dom";
const Footer = () => {
	const [scrollData,setScrollData] = useState([])
	const currentPath = useLocation().pathname

	useEffect(() => {
		// Axios.get(`${Baseurl}getFullCategory`)
		Axios.get(`https://s6uy37t1ak.execute-api.us-east-1.amazonaws.com/farmproduce-user-api/get_full_category`)
		.then(response => {
		  // Handle the successful response
		  console.log(response.data.results);
		  setScrollData( response.data.results);
		})
		.catch(error => {
		  // Handle any errors that occurred during the request
		  console.log('Error:', error);
		});
	  }, [])

	return (
		<>
{currentPath!='/ProductView' ?		
		<div className='footer'>
			<div className='footer-top-div'>
				<h2 className='footer-heading'>Farmproduce Footer</h2>
				{/* <div className='subscribe-input'>
					<input placeholder='Your email address' />
					{/* <button className='subscribe-button'>SUBSCRIBE</button> 
				</div>
				<p>To Get the latest news from us please subscribe your email</p> */}
			</div>
			<div className='information-div'>
				{/* <div className='first-div'>
					<p>INFO</p>
				// 	{/* <p> 
				// 		{/* <span>
                //     <IoLocationSharp/>
                // </span> 
				// 		{/* <span>
                //     1093 Marigold Lane,<br/> Coral Way, Miami, Florida, 33169
                // </span> 
				// 	 </p> // need to close
					<p>
						<span>
							<BsPhone />
						</span>
						<span>+1(856)325-0560</span>
					</p>
					<p>
						<AiFillTwitterCircle />
						<BsFacebook />
						<AiFillInstagram />
					</p>
				</div> */}
				<div className='second-div'>
					<p>NAVIGATE :</p>
					<p>Shipping & Returns</p>
					<p>Contact Us</p>
					<p>Blog</p>
				</div>
				<div className='third-div'>
					<p>CATEGORIES :</p>
					<p>Shop All</p>
					{scrollData.map((category,index)=>(<p key={index}>{category.category_name}</p>))}
					{/* <p>Beef</p>
					<p>Pork</p>
					<p>Lamb</p>
					<p>Fish</p>
					<p>Turkey</p> */}
				</div>
				<div>
					<p>POPULAR BREED :</p>
					<p>Cornish Cross</p>
					<p>Red Broiler</p>
					<p>Broad Breasted White</p>
					<p>View All</p>
				</div>
			</div>
			{/* <div className='Products-footer'>
				Beef | Pork | Lamb | Fish | Prawn | Fish | Prawn | Egg | Turkey | Duck |
				Emu
			</div> */}
			<div className='Copyrights'>
				<p>Powered by Jseven Technology @2023</p>
			</div>
		</div>
:null}</>
	
);
};


// Memoize the component to prevent unnecessary re-renders
const MemoizedFooter = React.memo(Footer);

const CachedFooter = () => {
  // Memoize the component instance
  const cachedComponent = useMemo(() => <MemoizedFooter />, []);

  return cachedComponent;
};

export default CachedFooter;
